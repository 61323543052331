<template>
  <div>
    <hero-bar> Order View </hero-bar>

    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced p-4"
        title="Order View"
        icon="account-multiple"
      >
        <p class="has-text-weight-bold">
          Client name:
          <span class="has-text-weight-normal">
            {{ order.client_first_name }} {{ order.client_last_name }}
          </span>
        </p>
        <p class="has-text-weight-bold">
          Client phone:
          <span class="has-text-weight-normal">
            {{ order.client_phone }}
          </span>
        </p>
        <p class="has-text-weight-bold">
          Client email:
          <span class="has-text-weight-normal">
            {{ order.client_email }}
          </span>
        </p>
        <div class="recap-container has-text-left mt-3">
          <p
            v-if="order.moveDate"
            class="has-text-weight-bold"
          >
            Moving date:
            <span class="has-text-weight-normal">{{
              order.moveDate
                ? format(new Date(order.moveDate), "dd-MM-yyyy hh:mm")
                : ""
            }}</span>
          </p>
          <p
            v-if="order.deliveryDate"
            class="has-text-weight-bold"
          >
            Delivery date:
            <span class="has-text-weight-normal">{{
              order.deliveryDate
                ? format(new Date(order.deliveryDate), "dd-MM-yyyy hh:mm")
                : ""
            }}</span>
          </p>
          <p
            v-if="order.pickupDate"
            class="has-text-weight-bold"
          >
            Pick-up date:
            <span class="has-text-weight-normal">{{
              order.pickupDate
                ? format(new Date(order.pickupDate), "dd-MM-yyyy hh:mm")
                : ""
            }}</span>
          </p>
        </div>

        <p
          class="
            has-text-left has-text-weight-bold
            is-size-4
            recap-container
            mt-4
          "
        >
          Informations about departure
        </p>
        <div class="recap-container has-text-left">
          <p
            v-if="order.street"
            class="has-text-weight-bold"
          >
            Address:
            <span
              class="has-text-weight-normal"
            >{{ order.street }}, {{ order.postalCode }},
              {{ order.city }}</span>
          </p>
          <p
            v-if="order.type"
            class="has-text-weight-bold"
          >
            Type:
            <span class="has-text-weight-normal">{{
              order.type
            }}</span>
          </p>
          <p
            v-if="order.area"
            class="has-text-weight-bold"
          >
            Area:
            <span class="has-text-weight-normal">{{
              order.area
            }}</span>
          </p>
          <p
            v-if="order.floor"
            class="has-text-weight-bold"
          >
            Floor:
            <span class="has-text-weight-normal">{{
              order.floor
            }}</span>
          </p>
          <p
            v-if="order.distance"
            class="has-text-weight-bold"
          >
            Distance to the street:
            <span class="has-text-weight-normal">{{
              order.distance
            }}</span>
          </p>
          <p
            v-if="order.elevator"
            class="has-text-weight-bold"
          >
            Elevator(min 8 persons):
            <span class="has-text-weight-normal">{{
              order.elevator
            }}</span>
          </p>
          <p
            v-if="order.elevatorUse"
            class="has-text-weight-bold"
          >
            The elevator can be used during the move?:
            <span class="has-text-weight-normal">{{
              order.elevatorUse
            }}</span>
          </p>
          <p
            v-if="order.closet"
            class="has-text-weight-bold"
          >
            Cellar:
            <span class="has-text-weight-normal">{{
              order.closet
            }}</span>
          </p>
        </div>
        <p
          v-if="!order.services.truck"
          class="
            has-text-left has-text-weight-bold
            is-size-4
            recap-container
            mt-4
          "
        >
          Informations about arrival
        </p>
        <div class="recap-container has-text-left">
          <p
            v-if="order.street"
            class="has-text-weight-bold"
          >
            Address:
            <span
              class="has-text-weight-normal"
            >{{ order.street }},
              {{ order.postalCode }},
              {{ order.city }}</span>
          </p>
          <p
            v-if="order.type"
            class="has-text-weight-bold"
          >
            Type:
            <span class="has-text-weight-normal">{{
              order.type
            }}</span>
          </p>
          <p
            v-if="order.area"
            class="has-text-weight-bold"
          >
            Area:
            <span class="has-text-weight-normal">{{
              order.area
            }}</span>
          </p>
          <p
            v-if="order.floor"
            class="has-text-weight-bold"
          >
            Floor:
            <span class="has-text-weight-normal">{{
              order.floor
            }}</span>
          </p>
          <p
            v-if="order.distance"
            class="has-text-weight-bold"
          >
            Distance to the street:
            <span class="has-text-weight-normal">{{
              order.distance
            }}</span>
          </p>
          <p
            v-if="order.elevator"
            class="has-text-weight-bold"
          >
            Elevator(min 8 persons):
            <span class="has-text-weight-normal">{{
              order.elevator
            }}</span>
          </p>
          <p
            v-if="order.elevatorUse"
            class="has-text-weight-bold"
          >
            The elevator can be used during the move?:
            <span class="has-text-weight-normal">{{
              order.elevatorUse
            }}</span>
          </p>
          <p
            v-if="order.closet"
            class="has-text-weight-bold"
          >
            Cellar:
            <span class="has-text-weight-normal">{{
              order.closet
            }}</span>
          </p>
        </div>
        <p
          class="
            has-text-left has-text-weight-bold
            is-size-4
            recap-container
            mt-4
          "
        >
          Services and Benefits
        </p>

        <div
          v-if="order.services"
          class="recap-container has-text-left"
        >
          <p
            v-if="order.services.cleaningHours !== 0"
            class="has-text-weight-bold"
          >
            {{ $t("general.cleaninghours") }}:
            <span class="has-text-weight-normal">{{
              order.services.cleaningHours
            }}</span>
          </p>
          <p
            v-if="order.services.packingUnpackingHours !== 0"
            class="has-text-weight-bold"
          >
            {{ $t("general.packingunpackinghours") }}:
            <span class="has-text-weight-normal">{{
              order.packingUnpackingHours
            }}</span>
          </p>
          <p
            v-if="order.services.parkingPermit"
            class="has-text-weight-bold"
          >
            {{ $t("general.parkingpermit") }} {{ $t("general.departure") }} -
            {{ !order.services.parkingPermit.departure.status ? "Yes" : "No" }}
            <span class="has-text-weight-normal">
              <b-icon
                v-if="order.services.parkingPermit.departure.status"
                icon="close"
                type="is-danger"
              />
              <b-icon
                v-if="!order.services.parkingPermit.departure.status"
                icon="check"
                type="is-success"
              /></span>
          </p>
          <!-- <p class="has-text-weight-bold" v-if="order.services.parkingPermit">
            {{ $t("general.parkingpermit") }} {{ $t("general.destination") }} -
            {{
              !order.services.parkingPermit.destination.status ? "Yes" : "No"
            }}
            <span class="has-text-weight-normal">
              <b-icon
                v-if="order.services.parkingPermit.destination.status"
                icon="close"
                type="is-danger" />
              <b-icon
                v-if="!order.services.parkingPermit.destination.status"
                icon="check"
                type="is-success"
            /></span>
          </p> -->
          <p
            v-if="order.services.extElevator"
            class="has-text-weight-bold"
          >
            {{ $t("general.elevator") }} {{ $t("general.departure") }} -
            {{
              !order.services.extElevator.departure.status
                ? "No"
                : order.services.extElevator.departure.hours
            }}
            {{
              !order.services.extElevator.departure.status
                ? ""
                : $t("general.hours")
            }}
            <span class="has-text-weight-normal">
              <b-icon
                v-if="!order.services.extElevator.departure.status"
                icon="close"
                type="is-danger"
              />
              <b-icon
                v-if="order.services.extElevator.departure.status"
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="order.services.extElevator"
            class="has-text-weight-bold"
          >
            {{ $t("general.elevator") }} {{ $t("general.destination") }} -
            {{
              !order.services.extElevator.destination.status
                ? "No"
                : order.services.extElevator.destination.hours
            }}
            {{
              !order.services.extElevator.destination.status
                ? ""
                : $t("general.hours")
            }}
            <span class="has-text-weight-normal">
              <b-icon
                v-if="!order.services.extElevator.destination.status"
                icon="close"
                type="is-danger"
              />
              <b-icon
                v-if="order.services.extElevator.destination.status"
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="!order.services.warranty.status"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.title11") }}
            <span class="has-text-weight-normal">
              <b-icon
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="order.services.warranty.status === true"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.title12") }}
            <span class="has-text-weight-normal">
              <b-icon
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="!order.services.cancellation.status"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph15") }}
            <span class="has-text-weight-normal">
              <b-icon
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="order.services.cancellation.status"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph16") }}
            <span class="has-text-weight-normal">
              <b-icon
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="order.services.workers"
            class="has-text-weight-bold"
          >
            {{ $t("general.movers") }} - {{ order.services.workers
            }}<span class="has-text-weight-normal">
              <b-icon
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="order.services.truck"
            class="has-text-weight-bold"
          >
            {{ $t("general.trucksize") }} - {{ order.services.truck }}m<sup>2</sup><span class="has-text-weight-normal">
              <b-icon
                icon="check"
                type="is-success"
              /></span>
          </p>
          <p
            v-if="order.services.hours"
            class="has-text-weight-bold has-text-capitalize"
          >
            Hours - {{ order.services.hours }}h<span
              class="has-text-weight-normal"
            >
              <b-icon
                icon="check"
                type="is-success"
              /></span>
          </p>
        </div>

        <p
          v-if="!order.services.truck"
          class="
            has-text-left has-text-weight-bold
            is-size-4
            recap-container
            mt-4
          "
        >
          Inventory
        </p>
        <div class="recap-container">
          <div class="has-text-left">
            <p
              v-for="element in order.inventory"
              :key="element.id"
              class="is-flex p-1"
            >
              {{ $i18n.locale === "en" ? element.name : element.name_fr }} -
              <span>{{ element.quantity }}</span>
            </p>
          </div>
        </div>
        <p
          v-if="order.disassembly.length !== 0"
          class="
            has-text-left has-text-weight-bold
            is-size-4
            recap-container
            mt-4
          "
        >
          Assembling & Dismantling
        </p>
        <div class="recap-container">
          <div class="has-text-left">
            <p
              v-for="element in order.disassembly"
              :key="'B' + element.id"
              class="is-flex p-1"
            >
              <span v-if="element.disassembly_quantity > 0">
                {{ $i18n.locale === "en" ? element.name : element.name_fr }} -
                <span v-if="element.disassembly_quantity > 0">
                  {{ element.disassembly_quantity }}
                </span>
              </span>
            </p>
          </div>
        </div>
        <p
          v-if="materialsFiltered.length !== 0"
          class="
            has-text-left has-text-weight-bold
            is-size-4
            recap-container
            mt-4
          "
        >
          Materials
        </p>
        <div class="recap-container">
          <div class="has-text-left">
            <p
              v-for="element in materialsFiltered"
              :key="'B' + element.id"
              class="is-flex p-1"
            >
              <span v-if="element.quantity > 0">
                {{ $i18n.locale === "en" ? element.name : element.name_fr }} -
                <span v-if="element.quantity > 0">
                  {{ element.quantity }}
                </span>
              </span>
            </p>
          </div>
        </div>
        <p
          class="
            has-text-left has-text-weight-bold
            is-size-5
            recap-container
            mt-4
          "
        >
          Source :
          <span class="has-text-info">{{ order.services.promotion }}</span>
        </p>
        <p
          class="
            has-text-left has-text-weight-bold
            is-size-3
            recap-container
            mt-4
          "
        >
          Total : <span class="has-text-success">{{ order.total }} €</span>
        </p>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from '@/axios'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { format } from 'date-fns'
export default {
  name: 'OrderView',

  components: {
    HeroBar,
    CardComponent
  },
  data () {
    return {
      format,
      order: {}
    }
  },
  computed: {
    materialsFiltered () {
      return this.order.material.filter((item) => item.quantity > 0)
    }
  },
  mounted () {
    axios
      .get(`orders/${this.$route.params.id}`)
      .then((result) => {
        this.order = { ...result.data }
        this.order.departure = JSON.parse(this.order.departure)
        this.order.destination = JSON.parse(this.order.destination)
        this.order.disassembly = JSON.parse(this.order.disassembly)
        this.order.inventory = JSON.parse(this.order.inventory)
        this.order.material = JSON.parse(this.order.material)
        this.order.services = JSON.parse(this.order.services)
      })
      .catch((error) => {
        console.log(error)
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: 'is-danger'
        })
      })
  },
  methods: {}
}
</script>
