<template>
  <div>
    <hero-bar>
      Setting Add
    </hero-bar>

    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Setting Add"
        icon="cog"
      >
        <b-field grouped>
          <b-field
            :required="true"
            label="Name"
          >
            <b-input v-model="setting.key" />
          </b-field>
          <b-field
            expanded
            label="Value"
          >
            <b-input v-model="setting.value" />
          </b-field>
        </b-field>

        <b-button
          type="is-info"
          @click="editSetting()"
        >
          Edit
        </b-button>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from '@/axios'
// import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'SettingsEdit',

  components: {
    HeroBar,
    CardComponent
    // Notification
  },
  data () {
    return {
      setting: {
        key: '',
        value: ''
      }
    }
  },
  mounted () {
    axios.get(`settings/${this.$route.params.id}`)
      .then(result => {
        this.setting = { ...result.data }
      })
      .catch(error => {
        console.log(error)
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: 'is-danger'
        })
      })
  },
  methods: {
    editSetting () {
      axios.put(`settings/${this.$route.params.id}`, this.setting).then((response) => {
        this.$router.push('/settings')
      })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
