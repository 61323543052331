<template>
  <div>
    <hero-bar> Inventory Item Edit </hero-bar>

    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Inventory Item Edit"
        icon="account-multiple"
      >
        <b-field grouped>
          <b-field :required="true" label="Name">
            <b-input v-model="inventoryItem.name" />
          </b-field>
          <b-field expanded label="Description">
            <b-input v-model="inventoryItem.description" />
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field :required="true" label="Name FR">
            <b-input v-model="inventoryItem.name_fr" />
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field label="Volume">
            <b-numberinput
              v-model="inventoryItem.volume"
              step="0.01"
              min="0"
              max="200"
            />
          </b-field>
          <b-field label="Price">
            <b-numberinput v-model="inventoryItem.price" step="0.01" min="0" />
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field>
            <b-checkbox v-model="inventoryItem.disassembly">
              Disassembly
            </b-checkbox>
          </b-field>
          <b-field label="Disassembly price">
            <b-numberinput
              v-model="inventoryItem.disassembly_price"
              step="0.01"
              min="0"
            />
          </b-field>
        </b-field>

        <b-field
          class="file is-primary"
          :class="{ 'has-name': !!inventoryItem.icon }"
        >
          <input
            id="upload"
            type="file"
            accept="image/*"
            class="upload-img"
            @change="onFileChange($event)"
          />
        </b-field>

        <b-button type="is-info" @click="editInventoryItem()"> Edit </b-button>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "@/axios";
// import Notification from '@/components/Notification'
import CardComponent from "@/components/CardComponent";
import HeroBar from "@/components/HeroBar";
export default {
  name: "Tables",

  components: {
    HeroBar,
    CardComponent,
    // Notification
  },
  data() {
    return {
      inventoryItem: {
        name: "",
        name_fr: "",
        description: "",
        volume: 0,
        price: 0,
        disassembly: false,
        disassembly_quantity: 0,
        disassembly_price: 0,
        icon: "",
      },
    };
  },
  mounted() {
    axios
      .get(`inventoryitems/${this.$route.params.id}`)
      .then((result) => {
        this.inventoryItem = { ...result.data };
        if (this.inventoryItem.disassembly === 1) {
          this.inventoryItem.disassembly = true;
        } else this.inventoryItem.disassembly = false;
      })
      .catch((error) => {
        console.log(error);
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: "is-danger",
        });
      });
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      //   var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.inventoryItem.icon = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    editInventoryItem() {
      axios
        .put(`inventoryitems/${this.$route.params.id}`, this.inventoryItem)
        .then((response) => {
          console.log(response);
          this.$router.push("/inventoryitems");
        })
        .catch((error) => {
          console.log(error);
          this.$buefy.toast.open({
            message: `Error: ${error.message}`,
            type: "is-danger",
          });
        });
    },
  },
};
</script>
